// auth.js
import {getAuth} from 'firebase/auth';
// Function to check if the user is authenticated
export function isAuthenticated() {
  // Assuming you store the user token in local storage
  // const userToken = localStorage.getItem('idToken');
   //const user = getAuth().currentUser
  const userId = sessionStorage.getItem('uid')
  // //console.log("The gotten user is: ", userId);
  /*if (!userId || userId === null) {*/
  ////console.log("The user:", user)
  if(!userId || userId == null){
    return false
  } else {
    return true
  }
  // Check if the user token exists and is not expired (you may need to decode and check expiration)
} 

// auth.js
// import { getAuth, signOut } from 'firebase/auth';

// const USER_LOGOUT_KEY = 'userLogout';

// // Function to check if the user is authenticated
// export function isAuthenticated() {
//   const user = getAuth().currentUser;
//   return !!user;
// }

// // Function to set up auto-logout when the tab is inactive
// export function setupAutoLogout() {
//   window.addEventListener('beforeunload', handleBeforeUnload);

//   function handleBeforeUnload() {
//     if (isAuthenticated()) {
//       // If tab is about to close, schedule logout
//       localStorage.setItem(USER_LOGOUT_KEY, new Date().toISOString());
//       // sessionStorage.clear();
//       this.$router.push('/');
//     }
//   }
// }

// // Function to log out the user and clear sessionStorage
// export function logoutUser() {
//   localStorage.removeItem(USER_LOGOUT_KEY);
//   signOut(getAuth()).then(() => {
//     // //console.log('User logged out');
//     // Optionally, you can clear local storage or perform other cleanup tasks
//     // sessionStorage.clear(); // Clear sessionStorage
//     localStorage.clear();
//     this.$router.push('/login/identifier');
//   });
// }

// // Function to check if the user should be auto-logged out
// export function checkAutoLogout() {
//   const logoutTimestamp = localStorage.getItem(USER_LOGOUT_KEY);
//   if (logoutTimestamp) {
//     // Clear the stored logout time
//     localStorage.removeItem(USER_LOGOUT_KEY);

//     const logoutTime = new Date(logoutTimestamp);
//     const currentTime = new Date();

//     // Adjust the timing based on your requirements
//     if (currentTime - logoutTime > 5 * 60 * 1000) {
//       // Auto-logout the user if the tab was about to close for more than 5 minutes
//       logoutUser();
//       // sessionStorage.clear();
//       localStorage.clear();
//       this.$router.push('/login/identifier');
//     }
//   }
// }
