<template>
    <button @click="signInWithGoogle" class="
        w-full lg:w-[426px] p-3 mt-8 bg-white rounded-lg border 
        border-primary-blue-500 flex justify-center items-center gap-2 
        text-active-button-color text-base font-semibold leading-8">
        <Icon class="w-6 h-6" icon="devicon:google" />
        <span class="flex-row ">
            {{ btnName }}
        </span>
    </button>
</template>

<script>
import { mapActions } from "vuex";
import { auth } from "@/firebase";
import { Icon } from '@iconify/vue';
import { signInWithPopup, GoogleAuthProvider, fetchSignInMethodsForEmail, getAuth } from 'firebase/auth';
export default {
    name: "LoginWithGoogle",
    props: ["btnName"],
    components: {
        Icon
    },
    data() {
        return {
            user: null,
        }
    },
    methods: {
        ...mapActions('authentication', ['CheckLoginEmail', 'setLoginWithGoogleData']),
        async signInWithGoogle() {
            try {
                const provider = new GoogleAuthProvider();
                const result = await signInWithPopup(auth, provider);
                const user = result.user;
                // //console.log("User logged in:", user);

                const checkedEmail = await this.checkEmailExists(user.email);

                if (checkedEmail === true) {
                    const userID = await this.loginUserWithFirebase(user.uid);
                    sessionStorage.setItem('uid', userID);
                    // //console.log("User id:", userID);
                    this.$router.push("/overview");
                } else if (checkedEmail === false) {
                    this.$store.dispatch('authentication/SET_EMAIL_REGISTERED_ERROR', 'Invalid email address, please enter a valid email address to continue.');
                    this.$router.push("/sign-up");
                } else {
                    // Handle other cases, such as network errors
                    // Display a message to the user or log the error
                    console.error('Unknown error occurred while checking email');
                }
            } catch (error) {
                console.error("Google sign-in error:", error);
                // Handle the error, e.g., display an error message to the user
            }
        },

        async loginUserWithFirebase(uid) {
            // Set the user ID to sessionStorage
            sessionStorage.setItem("uid", uid);
            return uid;
        },

        async checkEmailExists(email) {
            const auth = getAuth();

            try {
                const methods = await fetchSignInMethodsForEmail(auth, email);

                if (methods.length > 0) {
                    // Email exists, and associated with an account
                    // //console.log('Email exists:', email);
                    return true;
                } else {
                    // Email does not exist
                    // //console.log('Email does not exist:', email);
                    return false;
                }
            } catch (error) {
                console.error('Error checking email:', error.message);
                // Handle error, e.g., log or display an error message
                return false;
            }
        }

    }
}
</script>