<template>
    <div class="flex flex-wrap">

    </div><!--md:p-[128px]-->
    <div class=" max-w-full h-screen min-h-screen text-grey-color-500 ">
        <div class="max-w-[800px] mx-auto h-screen">
            <div class="grid grid-cols-12 w-full h-screen min-h-screen ">
                <div class="col-span-4 px-5 pt-14 bg-white h-screen flex flex-col justify-between">
                    <div class="">
                        <p class="text-primary-blue-500 font-semibold text-[10px] leading-4">
                            Investment highlights
                        </p>
                        <div class="mt-2 leading-4">
                            <p class="text-[10px] font-semibold">
                                Investment Stage
                            </p>
                            <p class="mt-1 text-[10px] text-grey-color-500">
                                {{ userProfile?.investmentStage || "Info goes here" }}
                            </p>
                        </div>
                        <div class="mt-3 leading-4">
                            <p class="text-[10px] font-semibold">
                                Funding Type
                            </p>
                            <p class="mt-1 text-[10px] text-grey-color-500">
                                {{ userProfile?.fundingType }}
                            </p>
                        </div>
                        <div class="mt-3 leading-4">
                            <p class="text-[10px] font-semibold">
                                Ask
                            </p>
                            <p class="mt-1 text-[10px] text-grey-color-500">
                                {{ userProfile?.currency }} {{ formatWithCommas(Number(userProfile?.fundingAmount)) }}
                            </p>
                        </div>
                        <div class="mt-3 leading-4">
                            <p class="text-[10px] font-semibold">
                                Use of fund
                            </p>
                            <div class="my-1" style="width: 90px; height:90px">
                                <Doughnut v-if="pieLoaded" :data="pieData" :options="chartOptions" />
                            </div>
                            <ul>
                                <li v-for="(value, key) in userProfile?.useOfFunds" :key="key">
                                    <p class="text-[8px] grey-color-500 flex items-center mb-1">
                                        <span class="mr-1">
                                            <Icon
                                                :style="{ color: pieColors[Object.keys(userProfile?.useOfFunds).indexOf(key)] }"
                                                class="w-1 h-1" icon="ic:round-circle" />
                                        </span>
                                        <span>{{ mapKeyToValue(key) }} </span>
                                        <span class="font-semibold"> - {{ value }}%</span>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-4">
                            <p class="text-[10px] font-semibold leading-4">
                                Team
                            </p>
                            <div class="mt-2 text-[10px] text-grey-color-500 leading-4">
                                <div class="">
                                    <div v-for="(member, index) in userProfile?.team" :key="`member-${index}`"
                                        class="flex items-center p-1 border-b rounded-md mt-4">
                                        <img src="../../assets/profile-avatar.svg"
                                            class="rounded-full w-6 h-6 object-cover mr-2" alt="" srcset="">
                                        <div class="">
                                            <p class="text-[10px] font-semibold">
                                                {{ member?.gender }}
                                            </p>
                                            <div class="flex gap-1">
                                                <p class="text-[8px]">
                                                    {{ member?.role }}
                                                </p>
                                                <p class="text-[8px] flex gap-1 items-center">
                                                    <Icon
                                                        class="text-black w-1 h-1" icon="ic:round-circle" />
                                                    <span v-if="member?.experience">
                                                        {{ member?.experience }} years exp
                                                    </span>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-auto flex gap-2 pb-2">
                        <p class="text-grey-color-500 text-[8px] shrink-0">
                            Powered by
                        </p>
                        <img src="../../assets/koppoh-logo-smalls.svg" class="w-10 object-contain " alt="" srcset="">
                    </div>

                </div>
                <div class="col-span-8 px-5 pt-14 bg-dashboard-bg leading-4 h-screen min-h-screen">
                    <p class="text-[10px] font-semibold text-primary-blue-500">
                        Business Profile
                    </p>
                    <div class="flex items-start justify-start mt-4 gap-10">
                        <div>
                            <p class="text-[10px] font-semibold">
                                Business sector
                            </p>
                            <p class="text-[10px] text-grey-color-500">
                                {{ userProfile?.businessSector }}
                            </p>
                        </div>
                        <div>
                            <p class="text-[10px] font-semibold">
                                Business stage
                            </p>
                            <p class="text-[10px] text-grey-color-500">
                                {{ userProfile?.businessStage }}
                            </p>
                        </div>
                        <div>
                            <p class="text-[10px] font-semibold">
                                Target customer
                            </p>
                            <p class="text-[10px] text-grey-color-500">
                                {{ userProfile?.targetAudience }}
                            </p>
                        </div>
                    </div>
                    <hr class="text-grey-color-100 my-4">
                    <p class="text-[10px] font-semibold text-primary-blue-500">
                        Business Overview
                    </p>
                    <div class="mt-2 text-grey-color-500">
                        <p class="text-[10px] font-semibold ">
                            Our vision
                        </p>
                        <p class="text-[10px]">
                            {{ userProfile?.companyVision }}
                        </p>
                    </div>
                    <div class="mt-3 text-grey-color-500">
                        <p class="text-[10px] font-semibold">
                            The problem we are trying to solve
                        </p>
                        <p class="text-[10px]">
                            {{ this.userProfile?.problem }}
                        </p>
                    </div>
                    <div class="mt-3 text-grey-color-500">
                        <p class="text-[10px] font-semibold">
                            Our proposed solution
                        </p>
                        <p class="text-[10px]">
                            {{ this.userProfile?.solution }}
                        </p>
                    </div>
                    <hr class="text-grey-color-100 my-4">
                    <div class=" text-grey-color-500">
                        <p class="text-primary-blue-500 font-semibold text-[10px] leading-4">
                            Key Milestones
                        </p>
                        <ul class="text-[10px] pl-2">
                            <li v-for="(milestone, index) in userProfile?.milestones" class="list-disc" :key="`milestone-${index}`">
                                <span class="font-semibold">{{ milestone?.date }}</span> - {{ milestone?.description }}
                            </li>
                        </ul>
                    </div>
                    <hr class="text-grey-color-100 my-4">
                    <div class=" text-grey-color-500 w-full">
                        <p class="text-primary-blue-500 font-semibold text-[10px] leading-4">
                            Financials
                        </p>
                        <div class="mt-2">
                            <div class="grid grid-cols-12 items-start gap-x-4 w-full">
                                <div class="col-span-8 w-full">
                                    <Line v-if="loaded" :data="chartData" :options="chartOptionsBar" />
                                </div>
                                <div class="col-span-2 w-full">
                                    <div class="flex gap-1 w-full">
                                        <div class="w-[4px] h-[12px] text-primary-blue-500 bg-primary-blue-500"> </div>
                                        <p class="text-[8px] text-grey-color-500">
                                            Revenue (USD)
                                        </p>
                                    </div>
                                    <div class="mt-2 flex gap-1 w-full">
                                        <div class="w-[4px] h-[12px] text-orange-accent-500 bg-orange-accent-500">
                                        </div>
                                        <p class="text-[8px] text-grey-color-500 shrink-0">
                                            Operating margin
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <!--<barchart :data="chartData" :options="chartOptions" />-->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import { mapGetters, mapActions } from "vuex";
import { barchart } from "@/components/reusable/BarChart.vue"
import { Bar, Doughnut, Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale, ArcElement, Legend, PointElement, LineElement } from 'chart.js'

ChartJS.register(Title, Legend, Tooltip, PointElement, LineElement, ArcElement, BarElement, CategoryScale, LinearScale)
export default {
    name: 'Contact Us',
    components: { Icon, barchart, Bar, Doughnut, Line },
    data() {
        return {
            unitIntervals: {
                "10": "ten",
                "100": "hundred",
                "1000": "thousand",
                "10000": "ten thousand",
                "100000": "hundred thousand",
                "1000000": "million",
                "10000000": "ten million",
                "100000000": "hundred million",
                "1000000000": "billion",
                "1000000000000": "trillion"
            },
            userProfile: {
                useOfFunds: {
                    product: 0,
                    saleAndMarketing: 0,
                    capitalExpenditure: 0,
                    researchAndDevelopment: 0,
                    operation: 0,
                    other: 0,

                }
            },
            chartData: null,
            chartData2: null,
            pieData: null,
            pieLoaded: false,
            pieColors: ['#EF2184', '#0E991D', '#262B8C', '#FF6B00', '#B01EBD', '#EFA521'],
            loaded: false,
            chartOptions: {
                plugins: {
                    legend: {
                        display: false, // Control legend visibility based on toggleLegend
                        position: this.toggleLegend ? "top" : "bottom",
                    },
                    title: {
                        display: false,
                        text: "",
                    },
                    tooltip: {
                        enabled: false
                    }
                },
            },
            divisor: 1,
            chartOptionsBar: {
                scales: {
                    x: {
                        display: true,
                        title: {
                            display: true,
                            text: 'Financial year',
                            color: '#000000',
                            font: {
                                size: 10,
                                lineHeight: 1.2,
                            },
                            padding: { top: 20, left: 0, right: 0, bottom: 0 }
                        },
                        ticks: {
                            font: {
                                size: 8 // Change this to your desired font size for the x-axis labels
                            }
                        },
                        grid: {
                            display: false // Remove x-axis grid lines
                        },
                    },
                    y1: {
                        type: 'linear',
                        position: 'left',
                        display: true,
                        title: {
                            display: true,
                            text: 'Amount',
                            color: '#000000',
                            font: {
                                size: 10,
                                lineHeight: 1.2
                            },
                            padding: { top: 30, left: 0, right: 0, bottom: 0 }
                        },
                        ticks: {
                            font: {
                                size: 8 // Change this to your desired font size for the y-axis labels
                            }
                        },
                        grid: {
                            display: false // Remove y-axis grid lines
                        },
                    },
                    y2: {
                        type: 'linear',
                        position: 'right',
                        display: true,
                        title: {
                            display: true,
                            color: '#000000',
                            font: {
                                size: 10,
                                lineHeight: 1.2
                            },
                            padding: { top: 30, left: 0, right: 0, bottom: 0 }
                        },
                        ticks: {
                            callback: function (value) {
                                return value + '%'; // Add the % suffix
                            },
                            font: {
                                size: 8 // Change this to your desired font size for the y-axis labels
                            }
                        },
                        grid: {
                            display: false // Remove y-axis grid lines
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false, // Control legend visibility based on toggleLegend
                        position: this.toggleLegend ? "top" : "bottom",
                    },
                    tooltip: {
                        enabled: false
                    },

                },
            },
        }
    },
    computed: {
        ...mapGetters('authentication', ['getResponseMessage']),
    },
    created() {
        //this.userId = sessionStorage.getItem('uid')
        let routeDets = this.$route;
        this.userId = routeDets.query.id
        // //console.log("The route details are: ", routeDets.query.id);
    },
    mounted() {
        this.getUser()
        // //console.log("Financials are: ", this.userProfile.financials)

    },
    unmounted() {

    },
    methods: {
        checkTeamMember(team) {
            if (team && !this.userProfile?.team) {
                this.userProfile.team = Object.keys(team).map(key => {
                    return {
                        id: key,
                        name: team[key].name,
                        role: team[key].role,
                        gender: team[key].gender,
                        experience: team[key].experience,
                        image: team[key].imageURL,
                    };
                });
            }
        },
        checkFinancials(financials) {
            if (this.financials && (!this.userProfile?.financials || this.userProfile?.financials == (null || undefined))) {
                // //console.log("Shit is empty", this.userProfile.financials);
                this.userProfile.financials = []
                this.userProfile.financials.push({
                    revenue: 0,
                    cost: 0,
                    financialDate: 2000,
                })
                // //console.log("Shit is no longer empty", this.userProfile.financials);
            } else {
                this.userProfile.financials = JSON.parse(financials)
            }
            var financialsLabel;
            const revenueArray = [];
            const costArray = [];
            const financialDateArray = [];
            const marginArray = [];
            
            this.divisor = this.createDivisor(this.userProfile.financials);
            this.chartOptionsBar.scales.y1.title.text = `Amount (${this.unitIntervals[String(this.divisor)]})`
            // Loop through each object in the array
            this.userProfile.financials.forEach(obj => {
                // Push values to respective arrays
                revenueArray.push(this.formatWithDivisor(obj.revenue));
                costArray.push(this.formatToMillions(obj.cost));
                financialDateArray.push(obj.financialDate);

                // Calculate and push margin cost to the marginArray
                const marginCost = Math.round(((obj.revenue - obj.cost) / obj.revenue) * 100);
                marginArray.push(marginCost);
            });

            // Now, revenueArray, costArray, financialDateArray, and marginArray contain the values of each property for each object.
            // //console.log("Revenue Array:", revenueArray);
            // //console.log("Cost Array:", costArray);
            // //console.log("Financial Date Array:", financialDateArray);
            // //console.log("Margin Cost Array:", marginArray);
            this.chartData = {
                labels: financialDateArray,
                datasets: [
                    {
                        label: '2',
                        backgroundColor: '#EFA521',
                        data: marginArray,
                        pointBackgroundColor: '#EFA521',
                        borderColor: '#EFA521',
                        yAxisID: 'y2'
                    },
                    {
                        label: 'Data One',
                        backgroundColor: '#2A2F9A',
                        data: revenueArray,
                        stack: 'combined',
                        type: 'bar',
                        yAxisID: 'y1'
                    },

                ]
            }

            this.loaded = true
        },
        formatWithDivisor(number) {
            return (number / this.divisor).toFixed(1).replace(/\.0$/, '');
        },

        formatToMillionOrThousand(number) {
            if (String(number).length < 6) {
                return (number / 1000).toFixed(1).replace(/\.0$/, ''); 
            } else if (String(number).length >= 6) {
                return (number / 1000000).toFixed(1).replace(/\.0$/, '');
            }
        },
        formatToMillions(number) {
            return (number / 1000000).toFixed(1).replace(/\.0$/, '');
        },
        formatWithCommas(number) {
            return number.toLocaleString(); // Format with commas
        },
        mapKeyToValue(key) {
            const mapping = {
                capitalExpenditure: 'Capital expenditure',
                operation: 'Operations',
                other: 'Other',
                product: 'Product',
                researchAndDevelopment: 'R & D',
                saleAndMarketing: 'Sales & marketing'
            };
            return mapping[key] || key;
        },
        checkUseOfFunds(funds) {
            if (!this.userProfile.useOfFunds || this.userProfile.useOfFunds == (null || undefined)) {
                this.userProfile.useOfFunds = {};
                this.userProfile.useOfFunds = {
                    product: 0,
                    saleAndMarketing: 0,
                    capitalExpenditure: 0,
                    researchAndDevelopment: 0,
                    operation: 0,
                    other: 0,

                }
            } else {
                this.userProfile.useOfFunds = JSON.parse(funds)
            }
            // //console.log("The keys: ", Object.keys(this.userProfile.useOfFunds))

            this.pieData = {
                labels: ["Product",
                    "Sales & marketing",
                    "Capital expenditure",
                    "R & D",
                    "Operations",
                    "Other",
                ],
                datasets: [{
                    label: 'Percentage',
                    data: Object.values(this.userProfile.useOfFunds),
                    backgroundColor: [
                        '#EF2184',
                        '#FF6B00',
                        '#0E991D',
                        '#B01EBD',
                        '#262B8C',
                        '#EFA521',
                    ],
                    hoverOffset: 4,
                    cutout: 25,
                }]
            };
            this.pieLoaded = true
        },

        createDivisor(financialsArray) {
            const revenueAmounts = financialsArray.map((item) => item.revenue);
            const highest = Math.max(...revenueAmounts);
            const revUnit = String(highest).length;
            const divisor = 10 ** revUnit / 10;

            return divisor;
        },

        /***************** */
        ...mapActions('authentication', ['getUserDetails']),
        async getUser() {
            let payload = this.userId;
            let userData = await this.getUserDetails(payload);
            this.userProfile = userData.user || this.userProfile;
            // //console.log("The teaser is: ", this.userProfile)
            this.checkTeamMember(userData.user?.Teammate)
            this.checkFinancials(userData.user?.financials)
            this.checkUseOfFunds(userData.user?.useOfFunds)
        }
    }
}
</script>


<style>
body {
    background-color: white;
    width: 595px;
}
@page {
    size: A4;
    margin: 0
}
@media print {
    .sheet-outer.A4, .sheet-outer.A5.landscape { 
        width: 210mm 
    }
}
</style>